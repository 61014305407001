import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 100px auto 40px;
  align-items: center;
  justify-content: space-evenly;
  /* background: url(${(props) => (props.avatar ? props.avatar : null)}); */
  background-position: center center;
  background-repeat: no-repeat;
  color: black;
  padding: 20px;

  .right-img {
    width: 40%;
    @media screen and (max-width: 420px) {
      width: 50%;
      margin-top: 20px;
    }
  }
  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 30%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 35%;
      height: 35px;
    }
  }

  .text {
    width: 40%;
    /* margin-right: 20px; */
    margin-left: 90px;
    text-align: left;

    h1 {
      font-size: 42px;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      font-size: 20px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 60%;
    }
  }
`;

export default ZoomInfoHeaderWrapper;
