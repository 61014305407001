import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import Heading from 'common/components/Heading';

import { useStaticQuery, graphql } from 'gatsby';

import PartnerSectionWrapper from './partner.style';

const LogoSlider = () => {
  const ClientData = useStaticQuery(graphql`
    {
      allStrapiHomePageSlider {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img6 {
            localFile {
              publicURL
            }
          }
          img8 {
            localFile {
              publicURL
            }
          }
          img9 {
            localFile {
              publicURL
            }
          }
          img11 {
            localFile {
              publicURL
            }
          }
          img12 {
            localFile {
              publicURL
            }
          }
          img14 {
            localFile {
              publicURL
            }
          }
          img15 {
            localFile {
              publicURL
            }
          }
          img16 {
            localFile {
              publicURL
            }
          }
          img17 {
            localFile {
              publicURL
            }
          }
          img18 {
            localFile {
              publicURL
            }
          }
          title
        }
      }
    }
  `);

  const data = ClientData.allStrapiHomePageSlider.nodes[0];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <PartnerSectionWrapper>
      <Heading as="h2" content={data.title} />
      <Slider className="slider" {...settings}>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img1.localFile.publicURL}`}
            alt="Ques"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img6.localFile.publicURL}`}
            alt="UI path"
          />
        </div>

        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img8.localFile.publicURL}`}
            alt="monster"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img9.localFile.publicURL}`}
            alt="hexaware"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img11.localFile.publicURL}`}
            alt="aajtak"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img12.localFile.publicURL}`}
            alt="nobroker"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img14.localFile.publicURL}`}
            alt="synergies"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img15.localFile.publicURL}`}
            alt="Wockhardt-logo.png"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img16.localFile.publicURL}`}
            alt="Wipro"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img17.localFile.publicURL}`}
            alt="CloudHedge"
          />
        </div>
        <div class="logo-container">
          <img
            class="logo-image"
            src={`${data.img18.localFile.publicURL}`}
            alt="3i-logo"
          />
        </div>
      </Slider>
    </PartnerSectionWrapper>
  );
};

export default LogoSlider;
