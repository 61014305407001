import React from "react";

// import Mark from "../../../../../common/assets/image/saasModern/mark.jpg";
import Clark from "../../../../../common/assets/image/saasModern/clark.jpg";
import Stuart from "../../../../../common/assets/image/saasModern/stuart.jpeg";
import Pierre from "../../../../../common/assets/image/saasModern/Pierre-Axel.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

import ReviewsWrapper, { ReviewsBox } from "./reviews.style";

const Reviews = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", fontSize: "32px" }}>
        What our users say about us
      </h2>
      <ReviewsWrapper>
        <ReviewsBox avatar={Pierre}>
          <div className="reviews-image"></div>
          <h3>Pierre-Axel B.</h3>
          <h4>Inside Sales Executive</h4>
          <p>
            It has the very potential to become one of the great one of the
            market Data management is almost perfect and very simular to
            Zoominfo Buying intent is interesting , cross finger to see if get
            some leads from it data for france is good so far .
          </p>
          <FontAwesomeIcon icon={faQuoteRight} />
        </ReviewsBox>
        <ReviewsBox avatar={Clark}>
          <div className="reviews-image"></div>
          <h3>Clark Bain</h3>
          <h4>
            Director of Business Development at software development company
          </h4>
          <p>
            "In a pinch, Clodura.AI solved my problem before lunch." Clodura.AI
            provides a single tool for relevant connections, reaching out to
            each individually. Like any power tool, it enables one to make
            errors, but Clodura.AI's Customer Success provides guidance to avoid
            mistakes. I look forward to working with Clodura.AI for years to
            come.
          </p>
          <FontAwesomeIcon icon={faQuoteRight} />
        </ReviewsBox>
        <ReviewsBox avatar={Stuart}>
          <div className="reviews-image"></div>
          <h3>Stuart K.</h3>
          <h4>Senior Vice President at Mid-Market Company</h4>
          <p>
            The search functionality and contacts delivered strong results for
            prospecting. We were looking for software companies, not end users,
            for many of our verticals, and Clodura was the only company we found
            to be able to deliver the information. In addition, there is
            additional functionality for marketing & email campaigns that don't
            exist with other tools like this.
          </p>
          <FontAwesomeIcon icon={faQuoteRight} />
        </ReviewsBox>
      </ReviewsWrapper>
    </>
  );
};

export default Reviews;
