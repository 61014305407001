import styled from "styled-components";

const ComparisonWrapper = styled.section`
  display: flex;
  flex-direction: column;
  /* background-color: #ebebeb; */
  justify-content: center;
  text-align: center;

  margin-top: -30px;
  padding: 20px;
  padding-top: 0px;

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 300;
    font-size: 14px;
    border-radius: 5px;
    width: 100px;
    height: 45px;

    @media screen and (max-width: 420px) {
      font-size: 10px;
      width: 70%;
    }
    @media screen and (max-width: 380px) {
      font-size: 6px;
      width: 50%;
    }
  }
  h2 {
    font-size: 32px;
  }
  @media screen and (max-width: 500px) {
    padding: 0px;
  }
`;

export const ComparisonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: none;

  > div {
    width: 30%;
  }
  h1 {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    font-size: small;
    margin-left: 0px;
    gap: 25px;
    h1 {
      font-size: 18px;
    }
    /* padding: 10px; */
  }

  @media screen and (max-width: 450px) {
    font-size: 10px;
    .fa-circle-xmark {
      font-size: 10px;
    }
    .fa-circle-check {
      font-size: 10px;
    }
  }

  > h4 {
    min-width: 20%;
    text-align: left;

    @media screen and (max-width: 500px) {
      min-width: 25%;
    }
  }
`;

export default ComparisonWrapper;
