import styled from "styled-components";

const ReviewsWrapper = styled.section`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ReviewsBox = styled.div`
  width: 32%;
  padding: 20px;
  text-align: center;
  /* margin: auto; */

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  > .reviews-image {
    margin: 0 auto;
    height: 150px;
    width: 150px;
    border-radius: 50% 50%;
    background: url(${(props) => (props.avatar ? props.avatar : null)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  > h4 {
    min-height: 50px;
    @media screen and (max-width: 1024px) {
      min-height: 80px;
    }
    @media screen and (max-width: 768px) {
      min-height: 100px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > p {
    color: #a3a3a3;
    font-size: 14px;

    min-height: 150px;

    @media screen and (max-width: 1024px) {
      min-height: 220px;
    }
    @media screen and (max-width: 768px) {
      min-height: 300px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > svg {
    font-size: 72px;
  }
`;

export default ReviewsWrapper;
