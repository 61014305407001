import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ZoomInfoHeaderWrapper from "./zoominfoHeader.style";
import { Link } from "gatsby";
const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiComparePageHeaderImage {
        nodes {
          headerImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiComparePageHeaderImage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <div className="text">
        <h1>
          Discover How Clodura's Advanced Features Outperform Competitors in
          Sales Intelligence
        </h1>
        <p>
          Users opt for Clodura.AI for its unparalleled accuracy in database,
          robust sales engine, and competitive pricing plans.
        </p>
        <Link href="/app/#/auth/register">
          <button>Free Sign Up</button>
        </Link>
      </div>

      <img
        className="right-img"
        src={`${img.headerImage.localFile.publicURL}`}
      />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
