import React from "react";

import ComparisonWrapper, { ComparisonRow } from "./comparison.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const ZoomInfo = () => {
  return (
    <ComparisonWrapper>
      <h2>Know What Makes Clodura.AI the Best Alternative</h2>
      {/* <div className="comparison-logos">
        <h2>Clodura.AI</h2>
        <h2>ZoomInfo</h2>
        <h2>Apollo.io</h2>
        <h2>Seamless.AI</h2>
        <h2>Lusha</h2>
      </div> */}
      <div className='comparisons'>
        <ComparisonRow>
          <h4></h4>
          <h2 className='pink pink-first head'>Clodura.AI</h2>
          <h2 className='row-last head'>ZoomInfo</h2>
          <h2 className='row-last head'>Apollo.io</h2>
          <h2 className='row-last head'>Seamless.AI</h2>
          <h2 className='row-last head'>Lusha</h2>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Price</h4>
          <p className='pink '>$39/Month</p>
          <p className='row-last'>$8000/year</p>
          <p className='row-last'>$49/month</p>
          <p className='row-last'>$1764/year</p>
          <p className='row-last'>$348</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Commitment</h4>
          <p className='pink'>Monthly</p>
          <p className='row-last'>Annual</p>
          <p className='row-last'>Monthly</p>
          <p className='row-last'>Annual</p>
          <p className='row-last'>Annual</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Credits</h4>
          <p className='pink'>Unlimited</p>
          <p className='row-last'>2000/day</p>
          <p className='row-last'>Unlimited</p>
          <p className='row-last'>250/Month</p>
          <p className='row-last'>480</p>
        </ComparisonRow>
        <ComparisonRow>
          <div className='first-div'>
            <h4>Data</h4>
            <h5>Email</h5>
            <h5>Availability</h5>
            <h5>Accuracy</h5>
            <h5>Update Frequency</h5>
          </div>
          <div className='pink' style={{ padding: "40px 0px" }}>
            <p>&nbsp;</p>
            <p className='txt'>Corporate</p>
            <p>600M</p>
            <p>95%</p>
            <p>90 Days</p>
          </div>
          <div className='pink-first' style={{ minWidth: "16.4%" }}>
            <p>&nbsp;</p>
            <p className='txt'>
              Personal/
              <br /> Corporate
            </p>
            <p>150M</p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16.3%" }}>
            <p>&nbsp;</p>
            <p className='txt'>
              Personal/
              <br /> Corporate
            </p>
            <p>250M</p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16%" }}>
            <p>&nbsp;</p>
            <p className='txt'>
              Personal/
              <br /> Corporate
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16%" }}>
            <p>&nbsp;</p>
            <p className='txt'>
              Personal/
              <br /> Corporate
            </p>
            <p>60M</p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
        </ComparisonRow>
        <ComparisonRow>
          <div className='first-div'>
            <h4>Search</h4>
            <h5>Basic Search</h5>
            <h5>Advanced Search</h5>
          </div>
          <div className='pink' style={{ padding: "30px 0" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16.3%" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16%" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16%" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
          <div className='pink-first' style={{ minWidth: "16%" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Technographics</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Product Companies</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Healthtech, Fintech etc</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Funding</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>
            Hiring Areas
            <br /> (Team hiring only)
          </h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Technologies</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Website Keywords</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Tags</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Mobile Numbers</h4>
          <p className='pink'>120M</p>
          <p className='row-last'>50M</p>
          <p className='row-last'>120M</p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>50M</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Included in the Plan</h4>
          <p className='pink'>12M</p>
          <p className='row-last'>50M</p>
          <p className='row-last'>25</p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>480</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>ORG Chart</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4 style={{ fontSize: "" }}>Integrated Email Campaigns</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Email Analytics</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>LinkedIn outreach</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Email Warmup</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Image Personalization</h4>
          <p className='pink'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow style={{ borderBottom: "none" }}>
          <h4>One Line Suggestions</h4>
          <p className='pink pink-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className='row-last'>
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow style={{ borderBottom: "none" }}>
          <h4></h4>
          <p className='row-last'></p>
          <div className='row-last'>
            <Link href='/zoominfo-competitor'>
              <button style={{ cursor: "pointer", fontFamily: "poppins" }}>
                Learn More
              </button>
            </Link>
          </div>

          <div className='row-last'>
            <Link href='/apollo-competitor'>
              <button style={{ cursor: "pointer", fontFamily: "poppins" }}>
                Learn More
              </button>
            </Link>
          </div>

          <div className='row-last'>
            <Link href='/seamless-competitor'>
              <button style={{ cursor: "pointer", fontFamily: "poppins" }}>
                Learn More
              </button>
            </Link>
          </div>

          <div className='row-last'>
            <Link href='/lusha-competitor'>
              <button style={{ cursor: "pointer", fontFamily: "poppins" }}>
                Learn More
              </button>
            </Link>
          </div>
        </ComparisonRow>
        <h6 style={{ display: "flex" }}>
          Disclaimer: This comparison is made based on preliminary research and
          the data that we could find and it may change based on new releases
        </h6>
      </div>
    </ComparisonWrapper>
  );
};

export default ZoomInfo;
