import React from 'react';
import ComparisonWrapper, { ComparisonRow } from './comparison.style';
import { Link } from 'gatsby';

const Comparison = () => {
  return (
    <ComparisonWrapper>
      <h2>
        Discover How Clodura.AI Outperforms Competitors
        <br /> in Sales Intelligence - Find Out More!
      </h2>
      <ComparisonRow>
        <div>
          <h1>Slintel Competitive Analysis</h1>
          <Link href="/slintel-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>

        <div>
          <h1>SalesIntel Competitive Analysis</h1>
          <Link href="/salesintel-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
        <div>
          <h1>Clearbit Competitive Analysis</h1>
          <Link href="/clearbit-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
        <div>
          <h1>Sales Navigator Competitive Analysis</h1>
          <Link href="/salesnavigator-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
      </ComparisonRow>
      <ComparisonRow>
        <div>
          <h1>UseArtemis Competitive Analysis</h1>
          <Link href="/useartemis-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>

        <div>
          <h1>LeadRocks Competitive Analysis</h1>
          <Link href="/leadrocks-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
        <div>
          <h1>Sales.Rock Competitive Analysis</h1>
          <Link href="/salesrocks-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
        <div>
          <h1>Eazyleads Competitive Analysis</h1>
          <Link href="/eazyleads-competitor">
            <button style={{ cursor: 'pointer', fontFamily: 'poppins' }}>
              Learn More
            </button>
          </Link>
        </div>
      </ComparisonRow>
    </ComparisonWrapper>
  );
};

export default Comparison;
