import styled from "styled-components";

const ComparisonWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  padding: 20px;
  padding-top: 0px;
  /* background-color: #ebebeb; */
  column-count: 4;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 0;
  }

  > h2 {
    font-size: 32px;
  }
  > h6 {
    margin-bottom: 5px;
    font-size: 500px;
  }

  .comparisons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ComparisonRow = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  border-bottom: 1px solid black;

  .fa-circle-xmark {
    font-size: 20px;
    color: red;
  }
  .fa-circle-check {
    font-size: 20px;
    color: green;
  }
  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 300;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    height: 35px;

    @media screen and (max-width: 420px) {
      font-size: 6px;
      width: 70%;
    }
  }
  .txt {
    font-size: 14px;
    @media screen and (max-width: 430px) {
      font-size: 4px;
    }
    @media screen and (max-width: 500px) {
      font-size: 7px;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: small;
  }
  /* @media screen and (max-width: 700px) {
    font-size: 8px;
  } */

  @media screen and (max-width: 450px) {
    font-size: 7.5px;
    .fa-circle-xmark {
      font-size: 10px;
    }
    .fa-circle-check {
      font-size: 10px;
    }
    h4 > {
      min-height: 10px;
    }
    > p {
      font-size: 7px;
    }
  }

  > h4 {
    min-width: 21%;
    text-align: left;

    /* @media screen and (max-width: 768px) {
      min-width: 180px;
    }

    @media screen and (max-width: 600px) {
      min-width: 140px;
    }*/

    @media screen and (max-width: 500px) {
      min-width: 25%;
    }
  }
  > .head {
    font-size: 20px;
    @media screen and (max-width: 880px) {
      font-size: 15px;
    }
    @media screen and (max-width: 670px) {
      font-size: 12px;
    }
    @media screen and (max-width: 450px) {
      font-size: 8px;
    }
  }

  > .first-div {
    min-width: 21%;
    text-align: left;
    /* align-items: center; */

    @media screen and (max-width: 500px) {
      min-width: 25%;
    }
  }

  > .row-last {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 40px;
    min-width: 15%;
    /* border-left: 5px dashed green; */
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    @media screen and (max-width: 500px) {
      margin-left: 0;
      min-width: 15%;
    }
  }

  > .pink {
    min-width: 16%;
    padding: 35px 5px;
    margin: 0;
    /* background-color: #f2fbfe; */
    background-color: #c5effc;
    /* background-color: #03baed; */

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      /* min-width: 150px; */
    }

    @media screen and (max-width: 450px) {
      min-width: 15%;
    }
  }

  > .pink-first {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 26px 0;
  }

  > .pink-last {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export default ComparisonWrapper;
