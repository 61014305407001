import styled from 'styled-components';

const PartnerSectionWrapper = styled.section`
  margin-top: -50px;
  max-width: 70%;
  margin: auto;

  .slider {
    margin-top: 20px;
  }

  .logo-container {
    width: 150px;
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  @media screen and (max-width: 576px) {
    max-width: 90%;
  }

  > h2 {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
`;

export default PartnerSectionWrapper;
