import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Ratings from 'containers/Custom/Zoominfo/Ratings';
import ZoomInfoHeader from '../containers/Custom/Landing/Zoominfo/MainComHeading';
import Comparison from '../containers/Custom/Landing/Zoominfo/MainComparisonSection';
import OtherComparison from '../containers/Custom/Landing/Zoominfo/OtherComp';
import Reviews from '../containers/Custom/Landing/Zoominfo/CompPageReviews';
import Footer from 'containers/AgencyModern/Footer';
import Seo from 'components/seo';
import LogoSlider from '../containers/SaasModern/Partner';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import G2Slider from '../containers/SaasModern/G2Badges';
import Directory from '../containers/Directory';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const Compare = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Clodura.AI Competitive Analysis: Compare Us With Our Rivals"
          description="See how Clodura.AI stacks up against its competitors. Compare features, pricing, and benefits to find the perfect lead gen platform for your business. Sign up for a free trial now."
          robots="index, follow"
          canonical="https://www.clodura.ai/compare-with-us/"
        />
        <ResetCSS />

        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <ZoomInfoHeader />
          <LogoSlider />
          <Comparison />
          <OtherComparison />
          <Reviews />
          <Ratings />
          <G2Slider />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Compare;
